declare var API_HREF: string;

// Dependencies
import axios from 'axios';
import { Redirect } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import React, { lazy, FC, ReactElement, ComponentType, useEffect } from 'react';

// Components
import Loading from '../component/loading/loading';

// Header
const Header = lazy(() => import('../component/header/header'));

// Objects
import { AppState } from '../reducers';

// Actions
import { setConfig } from '../component/header/redux/actions';
import { setProfile } from '../component/profile/redux/actions';
import { setTripToken } from '../component/trip/redux/actions';


type ProtectedRouteProps = {
    path: string,
    component: ComponentType
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component: Component, ...rest }): ReactElement => {
    const dispatch = useDispatch();
    const client_id = useSelector((state: AppState) => state.header.client_id);
    const profile = useSelector((state: AppState) => state.profile.profile);
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem("token");
    const trip_token = localStorage.getItem("trip_token");

    useEffect(() => {
        dispatch(setTripToken(trip_token !== null ? trip_token : ""))
    }, [])

    const target_client_id = window.location.host === "vat-dev.facilitatrip.com" ? 7 : (window.location.host === "vat.facilitatrip.com" ? 6 : 109);
    const isIframe = window.location !== window.parent.location;
    // const isIframe = true;
    // const isIframe = true;

    if (!isIframe && (user_id === null || user_id === "" || token === null || token === "" || (profile !== null && profile.is_shadow))) {
        return <Redirect from={''} to={'/login'} noThrow />;
    } else {
        if (client_id === null) {
            let customer_identifier = /.([^.]+)/.exec(window.location.host)[0];
            let domain_name = window.location.host;
            // let customer_identifier = 'vat-dev';
            // let domain_name = 'vat-dev.facilitatrip.com';

            if (window.location.host === 'localhost:3002') {
                // customer_identifier = 'dev';
                // domain_name = 'dev.facilitatrip.com';
                customer_identifier = 'vat-dev';
                domain_name = 'vat-dev.facilitatrip.com';
                // customer_identifier = 'vat';
                // domain_name = 'vat.facilitatrip.com';
            }
            axios({
                method: 'GET',
                url: `${API_HREF}instances/?customer_identifier=${customer_identifier}&domain_name=${domain_name}`
            }).then((response) => {
                dispatch(setConfig(response.data));
                if (user_id !== null && token !== null) {
                    axios({
                        method: 'GET',
                        headers: {
                            'Authorization': `Token ${localStorage.getItem('token')}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Accept-Language': 'fr'
                        },
                        url: `${API_HREF}client/${response.data.client_id}/user/${user_id}/?all_user=true`
                    }).then((response) => {
                        console.log('response : ', response);
                        dispatch(setProfile(response.data));
                    }).catch((error) => {
                        console.log('error : ', error);
                    });
                }
            }).catch((error) => {
                console.log('error : ', error);
            });
            return <Loading />;
        }
    }

    return (
        <>
            {
                !isIframe && profile !== null && !profile.is_shadow && <Header />
            }
            <Component {...rest} />
        </>
    );
};

export default ProtectedRoute;